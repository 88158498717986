main {
  background: $background;
  position: relative;
  display: block;
  @include expand();
  z-index: 1;

  & > .main {
    position: relative;
    @include expand();

    & > .updater {
      position: absolute;
      left: 50%;
      bottom: 30px;
      width: 100%;
      max-width: 960px;
      padding: 0px 15px;
      transform: translateX(-50%);
      z-index: 9999;

      & > .update {
        background: $button-secondary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px;
        border-radius: 10px;
        border-bottom: 1px solid rgba(#000, .1);

        & > .text {
          font-family: "Montserrat", sans-serif;
          color: #fff;
          font-weight: 700;
        }

        & > .action {
          & > .btn {
            background: #fff;
            padding: 8px 15px;
            border-radius: 15px;
            font-family: "Montserrat", sans-serif;
            color: $button-secondary;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;

            &:hover {
              opacity: .8;
            }
          }
        }
      }
    }

    & > ui-view.view {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      @include expand();
    }
  }

  //&:after {
  //  background-repeat: no-repeat;
  //  background-size: contain;
  //  background-position: center center;
  //  position: absolute;
  //  left: 50%;
  //  top: 50%;
  //  width: 60%;
  //  max-width: 275px;
  //  height: 100%;
  //  max-height: 375px;
  //  transform: translate(-50%, -50%);
  //  content: '';
  //  z-index: -1;
  //}
}
