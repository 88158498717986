modal[md-name="pass"] {
  & > .modal {
    & > .modal-body {
      & > .modal-content {
        & > * {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0px;
          }
        }

        & > .pass {
          @include card();
          text-align: center;
          color: #fff;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 20px;

          & > * {
            position: relative;
            padding-bottom: 20px;
            margin-bottom: 20px;
            
            &:last-child {
              padding-bottom: 0px;
              margin-bottom: 0px;
              
              &:after {
                display: none;
              }
            }

            &:after {
              background: rgba(#000, .15);
              position: absolute;
              left: 50%;
              bottom: 0px;
              width: 100px;
              height: 2px;
              transform: translateX(-50%);
              content: '';
            }
          }
        }

        & > .user {
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
        }

        & > .leo {
          margin: 0 auto;
          max-width: 50%;

          img {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
