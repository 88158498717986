kids {
  display: block;

  & > .kids {
    .card {
      @include card();

      & > .content {
        padding: 0px 15px;
        text-align: center;
        margin-bottom: 20px;

        & > .title {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
        }

        & > .age {
          margin-bottom: 10px;
          font-weight: 600;
        }
      }

      & > .actions {
        @include margin-bottom(10px);
      }
    }

    & > .cards {
      @include margin-bottom(30px);
    }
  }
}

register {
  display: block;

  & > .register {
    & > .form {
      & > .groups {
        &.important {
          @include card();
        }

        & > .title {
          color: $primary-color;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        & > .fields {
          & > .field {
            &.repeatable {
              & > .inputs {
                @include card();
                @include margin-bottom(30px);

                & > .fields {
                  @include margin-bottom(10px);

                  & > .field {
                    & > .label {
                      opacity: .8;
                    }
                  }
                }

                & > .actions {
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
