modal[md-name="onboarding"] {
  & > .modal {
    & > .modal-body {
      & > .modal-content {
        & > .onboarding {
          @include margin-bottom(15px);

          & > .title, & > .subtitle {
            font-weight: 700;
          }

          & > .title {
            font-size: 18px;
          }

          & > .intro {
            font-weight: 600;
          }

          & > .subtitle {
            margin-top: 30px;
            font-size: 16px;
            text-transform: uppercase;
            color: $primary-color;
          }

          ul {
            list-style: none;
            @include margin-bottom(10px);

            & > li {
              &:before {
                font-family: "FontAwesome";
                content: '\f058';
                margin-right: 4px;
              }
            }
          }

          & > .actions {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
