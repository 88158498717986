summary {
  display: block;

  & > .summary {
    @include margin-bottom(30px);

    & > .ads {
      margin-top: 30px;
      text-align: center;

      & > .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 15px;
      }

      & > .actions {
        margin-top: 30px;
        @include margin-bottom(15px);
      }
    }

    & > .intro {
      & > .title {
        font-size: 28px;
        font-weight: 700;
      }

      & > .subtitle {
        font-size: 18px;
        font-weight: 700;
      }
    }

    & > .dates {
      @include margin-bottom(20px);

      & > .date {
        @include card();

        & > .title {
          display: flex;
          align-items: center;

          & > .text {
            flex: 1 1 auto;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
          }

          & > .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 auto;
            width: 32px;
            height: 32px;
            cursor: pointer;
          }
        }

        & > .slots {
          margin-top: 20px;
          @include margin-bottom(15px);

          & > .slot {
            background: $primary-color;
            display: flex;
            align-items: center;
            padding: 15px 15px;
            border-radius: 4px;
            color: #fff;
            @include margin-right(15px);

            &.chicks,
            &.foxes,
            &.pirates,
            &.juniors,
            &.teens {
              background: $pink;
            }

            &.activity {
              background: $background;
              color: $text-color;

              & > .name {
                & > .content {
                  & > .text {
                    color: $text-color;
                    overflow: auto;
                    text-overflow: clip;
                    white-space: normal;
                    width: auto;
                  }
                }
              }

              & > .details {
                & > .seats {
                  color: $text-color;
                }
              }
            }

            &.place {
              background: $dark-blue;
            }

            &.restaurant_special {
              background: $orange;

              & > .name > .info > .pass > .pass > .button {
                color: $orange;
              }
            }

            &.restaurant {
              & > .name > .info > .pass > .pass > .button {
                color: $primary-color;
              }
            }

            & > .name {
              flex: 1 1 auto;

              & > .content {
                display: flex;
                align-items: center;

                & > .icon {
                  flex: 0 0 auto;
                  max-width: 32px;
                  margin-right: 10px;

                  img {
                    max-width: 100%;
                    width: 100%;
                  }
                }

                & > .text {
                  flex: 1 1 auto;
                  color: #fff;
                  font-weight: 700;
                  text-transform: uppercase;
                  font-size: 16px;
                  @include ellipsis();
                }
              }

              & > .info {
                font-weight: 600;
                font-size: 12px;
                margin-top: 5px;

                &.restaurant {
                  text-transform: uppercase;
                  font-weight: 800;
                }

                & > .pass {
                  margin-top: 5px;
                }
              }
            }

            & > .details {
              flex: 0 0 auto;
              font-weight: 700;
              text-align: center;
              max-width: 50px;

              & > .time {
                color: $secondary-color;
                font-size: 18px;
              }

              & > .seats {
                font-size: 14px;
              }
            }

            & > .actions {
              flex: 0 0 auto;

              & > .action {
                & > .button {
                  padding: 10px 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}
