restaurants {
  display: block;

  & > .restaurants {
    @include margin-bottom(30px);

    & > .dates {
      @include margin-bottom(20px);

      & > .date {
        @include card();

        & > .title {
          display: flex;
          align-items: center;

          & > .text {
            flex: 1 1 auto;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
          }

          & > .icon {
            flex: 0 0 auto;
          }
        }

        & > .slots {
          margin-top: 20px;
          @include margin-bottom(15px);

          & > .slot {
            background: $primary-color;
            display: flex;
            align-items: center;
            padding: 15px 15px;
            border-radius: 4px;
            color: #fff;
            @include margin-right(15px);

            &.no {
              background: rgba($primary-color, 0.3);
            }

            & > .name {
              flex: 1 1 auto;

              & > .content {
                display: flex;
                align-items: center;

                & > .icon {
                  flex: 0 0 auto;
                  max-width: 32px;
                  margin-right: 10px;

                  img {
                    max-width: 100%;
                    width: 100%;
                  }
                }

                & > .text {
                  flex: 1 1 auto;
                  color: #fff;
                  font-weight: 700;
                  text-transform: uppercase;
                  font-size: 16px;
                  @include ellipsis();
                }
              }

              & > .info {
                font-weight: 600;
                font-size: 12px;
                margin-top: 5px;

                &.restaurant {
                  text-transform: uppercase;
                  font-weight: 800;
                }

                & > .pass {
                  margin-top: 5px;
                }
              }
            }

            & > .details {
              flex: 0 0 auto;
              font-weight: 700;
              text-align: center;

              & > .row {
                & > .time {
                  color: $secondary-color;
                  font-size: 18px;
                }

                & > .seats {
                  font-size: 14px;
                }
              }
            }

            & > .actions {
              flex: 0 0 auto;
            }
          }
        }
      }
    }
  }
}

preferences {
  display: block;

  & > .preferences {
    @include margin-bottom(30px);

    & > .half-board {
      & > .title {
        color: $primary-color;
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      & > .tabs {
        background: $button-light-grey;
        display: flex;
        color: #fff;
        border-radius: $button-border-radius;
        overflow: hidden;

        & > .tab {
          flex: 1 1 50%;
          border-radius: 0px;

          &.active {
            background: $button-secondary;
          }
        }
      }
    }

    & > .meals {
      @include margin-bottom(20px);

      & > .meal {
        & > .title {
          color: $primary-color;
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        & > .slots {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          & > .slot {
            margin-right: 10px;
            margin-bottom: 10px;

            & > .label {
              display: block;
            }
            
            & > .input {
              display: none;

              &:checked + .label {
                background: $button-secondary;
              }
            }
          }
        }
      }
    }
  }
}
