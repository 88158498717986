slot {
  display: block;

  & > .slots {
    @include margin-bottom(30px);

    & > .dates {
      @include margin-bottom(20px);

      & > .date {
        @include card();

        & > .title {
          display: flex;
          align-items: center;

          & > .text {
            flex: 1 1 auto;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
          }

          & > .icon {
            flex: 0 0 auto;
          }
        }

        & > .slots {
          margin-top: 20px;
          @include margin-bottom(15px);

          & > .wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-right: -10px;
            margin-bottom: -10px;

            & > .slot {
              margin-right: 10px;
              margin-bottom: 10px;

              &.active {
                background: $secondary-color;
              }

              &:not(.active).disabled {
                position: relative;
                background: $button-disabled;
                color: $button-light-grey;

                &:after {
                  background: $button-light-grey;
                  position: absolute;
                  top: 50%;
                  left: 10px;
                  right: 10px;
                  height: 3px;
                  margin-top: -1px;
                  transform: rotate(-35deg);
                  content: '';
                }
              }

              & > .seats {
                margin-top: 4px;
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
