modal {
  display: block;
  color: $text-color;

  & > .modal {
    background: $background-modal;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    & > .modal-body {
      position: relative;

      & > .modal-header {
        background: #fff;
        position: relative;
        padding: 50px 15px 30px 15px;
        text-align: center;

        & > .title {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
        }

        & > .subtitle {
          font-size: 14px;
        }

        & > .close {
          position: absolute;
          top: 0px;
          right: 0px;
          padding: 10px;
          font-size: 18px;
          color: $primary-color;
          cursor: pointer;
          z-index: 1;
        }
      }

      & > .modal-content {
        &:not(.has-padded-content) {
          padding: 15px;
        }
      }
    }

    &:not(.is-msie) {
      display: flex;
      border-radius: $border-radius;

      &.can-scroll {
        overflow: hidden;
      }

      & > .modal-body {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > .modal-header {
          flex: 0 0 auto;
        }

        & > .modal-content {
          flex: 1 1 auto;

          & > .footer {
            display: flex;
            margin-top: 15px;

            & > .separator {
              flex: 1 1 auto;
            }
          }

          &.has-flex-content {
            display: flex;
          }
        }
      }
    }
  }
}

@media (max-width: 599px) {
  modal {
    & > .modal {
      position: fixed;
      top: 8px;
      left: 10px;
      right: 10px;
      bottom: 8px;
      visibility: hidden;
      opacity: 0;
      transform: translate(0%, -10%);
      transition: .25s;

      &.is-open {
        will-change: transform;
        transform: translate(0%, 0%);
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@media (min-width: 600px) {
  modal {
    & > .modal-backdrop {
      background: rgba(#000, .8);
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      visibility: hidden;
      opacity: 0;
      transition: .25s;

      &.is-open {
        visibility: visible;
        opacity: 1;
      }
    }

    & > .modal {
      &:not(.is-sidebar) {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 650px;
        max-height: 80%;
        visibility: hidden;
        opacity: 0;
        transform: translate(-50%, -60%);
        transition: .2s;
        transition-delay: .1s;

        &.is-open {
          transform: translate(-50%, -50%);
          visibility: visible;
          opacity: 1;
          will-change: transform;
        }

        &.is-desktop-height-80 {
          height: 80%;
        }
      }
    }
  }
}
