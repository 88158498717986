booking {
  display: block;

  & > .booking {
    @include margin-bottom(30px);

    & > .title {
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }

    & > .groups {
      @include card();
      padding: 15px;
      @include margin-bottom(20px);

      & > .group {
        @include card($background);
        padding: 15px 10px;
        @include margin-bottom(15px);

        & > .title {
          color: $primary-color;
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 15px;

          & > * {
            display: inline;
          }
        }

        & > .slots {
          & > .wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: -10px;
            margin-right: -9px;

            & > .slot {
              margin-right: 9px;
              margin-bottom: 10px;

              &.active {
                background: $secondary-color;
              }

              &:not(.active).disabled {
                position: relative;
                background: $button-disabled;
                color: $button-light-grey;

                &:after {
                  background: $button-light-grey;
                  position: absolute;
                  top: 50%;
                  left: 10px;
                  right: 10px;
                  height: 3px;
                  margin-top: -1px;
                  transform: rotate(-35deg);
                  content: '';
                }
              }

              & > .seats {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }

        & > .counter {
          display: flex;
          align-items: center;
          border: 2px solid $button-light-grey;
          border-radius: $button-border-radius;
          overflow: hidden;

          & > .button {
            padding: 12px 20px;
            border-radius: 0px;
          }

          & > .value {
            flex: 1 1 auto;
            text-align: center;

            & > .number {
              font-size: 16px;
              font-weight: 700;
            }
          }
        }
      }

      & > .actions {
      }
    }

    & > .actions {
      @include margin-bottom(15px);
    }
  }
}
