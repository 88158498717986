loader {
  display: block;

  & > .loader {
    background: $secondary-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 30px;
    border-radius: $border-radius;
    color: #fff;
    z-index: 999;

    & > .icon {
      width: 32px;
      height: 32px;
      margin: 0 auto;
      margin-bottom: 20px;
      border: 3px solid #fff;
      border-top-color: transparent;
      border-radius: 50%;
      animation: fa-spin 2s infinite linear;
    }

    & > .text {
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
