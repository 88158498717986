auth {
  display: block;
  @include expand();

  & > .auth {
    display: flex;
    flex-direction: column;
    @include expand();

    & > .top-bar, & > .tab-bar {
      flex: 0 0 auto;
    }

    & > .top-bar {
      position: relative;
      background: $top-bar-color;
      display: flex;
      justify-content: center;
      align-items: center;

      &.has-back-button {
        & > .title {
          text-indent: -35px;
        }
      }

      & > .actions {
        flex: 0 0 auto;

         & > .action {
          & > .back {
            padding: 8px 10px;
            display: flex;
            align-items: center;
            color: #fff;

            & > .fa {
              font-size: 21px;
              line-height: 21px;
            }

            & > .text {
              margin-left: 5px;
              font-weight: 600;
            }
          }
        }
      }

      & > .title {
        flex: 1 1 auto;
        padding: 12px 45px;
        color: #fff;
        font-size: 17px;
        font-weight: 700;
        text-align: center;
        @include ellipsis();
      }
    }

    & > .screen {
      background: $background;
      position: relative;
      display: flex;
      flex: 1 1 auto;
      overflow: hidden;

      & > .content {
        flex: 1 1 auto;

        &.has-modal-opened {
          overflow-y: hidden;
          -webkit-overflow-scrolling: auto;
        }

        & > ui-view.view {
          padding: 20px 15px;
          margin: 0 auto;
          max-width: $max-width;
        }
      }
    }

    & > .tab-bar {
      background: $tab-bar-background;
      border-top: 1px solid $light-grey;

      & > .tabs {
        display: flex;
        max-width: $max-width;
        padding: 4px 2px;
        margin: 0 auto;
        @include margin-right(2px);

        & > .tab {
          flex: 1 1 20%;

          & > .link {
            display: block;
            padding: 6px 4px;
            border-radius: 6px;
            color: $tab-bar-color;
            text-align: center;

            &.active {
              background: $tab-bar-color;
              color: #fff;
            }

            &:not(.active):hover {
              background: rgba($tab-bar-color, .1);
            }

            & > .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 54px;
              height: 54px;
              margin: 0 auto;
              margin-bottom: 4px;

              & > img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            & > .text {
              font-size: 11px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 599px) {
  auth {
    & > .auth {
      & > .top-bar {
        & > .actions {
          & > .action {
            & > .back {
              & > .text {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
