profile {
  display: block;

  & > .profile {
    @include margin-bottom(30px);

    & > .items {
      @include card();
      @include margin-bottom(15px);

      & > .item {
        & > .label {
          font-weight: 700;
        }
      }
    }

    & > .actions {
      @include margin-bottom(15px);
    }
  }
}

profile-form {
  display: block;

  & > .profile-form {
    @include margin-bottom(30px);

    & > .form {
      & > .fields {
        & > .field {
          & > .inputs {
            display: flex;
            @include margin-right(10px);

            & > .button {
              &.active {
                background: $button-secondary;
              }
            }
          }

          & > .allow {
            margin-top: 15px;
            display: block;
          }
        }
      }

      & > .terms {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 599px) {
  profile-form {
    & > .profile-form {
      & > .form {
        & > .fields {
          & > .field {
            & > .inputs {
              display: block;
              @include margin-right(0px);
              @include margin-bottom(10px);
            }
          }
        }
      }
    }
  }
}
