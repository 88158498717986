modal[md-name="terms"] {
  .terms {
    h1, h2, h3 {
      margin: 0px;
      color: $primary-color;
      text-transform: uppercase;
    }

    h1 {
      font-size: 18px;
      margin-bottom: 20px;
    }

    h2, h3 {
      margin-top: 20px;
      margin-bottom: 5px;
    }

    h2 {
      font-size: 16px;
    }

    h3 {
      color: $secondary-color;
    }

    ul, ol {
      list-style-position: inside;

      li {
        & > * {
          padding-left: 20px;
        }
      }
    }

    p, ul, ol {
      margin-bottom: 10px;
    }

    & > .actions {
      margin-top: 30px;
    }
  }
}

login {
  background: $primary-color;
  display: block;
  @include expand();

  & > .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 30px;
    max-width: 375px;
    margin: 0 auto;
    @include expand();

    & > .logo {
      max-width: 75%;
      margin: 0 auto;
      margin-bottom: 30px;

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    & > .title, & > .subtitle {
      color: #fff;
      text-align: center;
    }

    & > .title {
      font-size: 24px;
      margin-bottom: 5px;
      font-weight: 700;
    }

    & > .subtitle {
      font-size: 16px;
      margin-bottom: 30px;
    }

    & > .form {
      & > .fields {
        margin-bottom: 30px;

        & > .field {
          &.checkbox {
            color: #fff;
          }
        }
      }
    }
  }
}
