calendar {
  display: block;

  & > .calendar {
    .card {
      @include card();

      & > .content {
        padding: 0px 15px;
        text-align: center;
        margin-bottom: 20px;

        & > .title {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
      }

      & > .actions {
        @include margin-bottom(10px);
      }

      &.restaurants {
        & > .special {
          background-color: #012F59;
          padding: 15px;
          margin-top: 30px;
          border-radius: $border-radius;


          & > .title {
            text-align: center;
            color: white;
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          & > .sub-title {
            text-align: center;
            color: white;
            font-size: 13px;
            margin-bottom: 10px;
          }

          & > .actions {
            @include margin-bottom(15px);
          }
        }
      }

      &.kids {
        @include margin-bottom(20px);

        & > .children {
          background: #fff;
          padding: 15px 15px;
          padding-bottom: 5px;
          border-radius: $border-radius;

          & > .title {
            color: $secondary-color;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
          }

          & > .child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $light-grey;
            padding: 10px 0px;

            &:last-child {
              border-bottom: 0px;
            }

            & > .title {
              & > .name {
                font-weight: 700;
              }
            }
            
            & > .actions {
              display: flex;
              @include margin-right(10px);
            }
          }
        }
      }
    }

    & > .cards {
      @include margin-bottom(30px);
    }
  }
}
