@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin center() {
  margin-left: auto;
  margin-right: auto;
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 1px;
}

@mixin expand() {
  height: 100%;
  overflow: hidden;
}

@mixin scrollable() {
  overflow-y: auto;
  overflow-x: hidden;
  //-webkit-overflow-scrolling: touch;
  -ms-overflow-style: scrollbar;
}

@mixin card($bg: $bg-card) {
  background: $bg;
  padding: 15px 15px;
  border-radius: $border-radius;
}

@mixin margin-bottom($size) {
  & > * {
    margin-bottom: $size;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

@mixin margin-right($size) {
  & > * {
    margin-right: $size;

    &:last-child {
      margin-right: 0px;
    }
  }
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

html, body {
  @include expand();
}

body {
  background: #F5F6F8;
  font-family: 'Raleway', sans-serif;
  color: $text-color;
  font-size: 14px;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ui-view {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

img {
  vertical-align: bottom;
}

input, button {
  background: transparent;
  border: 0px;
  border-radius: 0px;
  outline: none;
  color: inherit;
}

button {
  cursor: pointer;
}

.scrollable {
  @include scrollable();
}

.no-scrollable {
  overflow-y: hidden;
  -webkit-overflow-scrolling: auto;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex-justify-center {
  justify-content: center;
}

.cursor {
  &.pointer {
    cursor: pointer;
  }
}

.intro {
  @include margin-bottom(10px);
}

.form {
  @include margin-bottom(30px);

  .fields {
    @include margin-bottom(20px);

    & > .field {
      & > .label {
        display: block;
        font-weight: 600;
        margin-bottom: 5px;
      }

      & > .input {
        background: #fff;
        display: block;
        font-family: 'Raleway', sans-serif;
        padding: 15px 20px;
        width: 100%;
        border: 1px solid $light-grey;
        outline: none;
      }

      &.radio, &.checkbox {
        & > .inputs {
          @include margin-bottom(5px);

          & > .radio, & > .checkbox {
            & > .label {
              cursor: pointer;

              &:before {
                margin-right: 5px;
                font-family: "FontAwesome";
                content: '\f1db';
                font-size: 16px;
              }
            }
            
            & > .input {
              display: none;

              &:checked + .label {
                &:before {
                  content: '\f058';
                }
              }
            }
          }
        }
      }
    }
  }

  & > .actions {
    @include margin-bottom(10px);
  }
}
