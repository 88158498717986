alert {
  display: block;

  .alert {
    &.toast {
      position: fixed;
      bottom: 30px;
      left: 0px;
      right: 0px;
      display: flex;
      justify-content: center;
      pointer-events: none;
      z-index: 400;
      margin-bottom: 0px;

      .message {
        background: rgba(#000, .9);
        max-width: 375px;
        margin: 0px 15px;
        color: #fff;
        text-align: center;
        border-radius: 100px;

        .body {
          padding: 10px 20px;
        }
      }

      &.ng-enter {
        opacity: 0;
        transform: translate(0%, 100%);
      }

      &.ng-enter-active {
        transform: translate(0%, 0%);
        will-change: transform;
        transition: .3s;
        opacity: 1;
      }
    }

    &.confirm, &.prompt, &.textarea, &.prompt_date, &.prompt_number, &.prompt_select {
      position: fixed;
      background: rgba(#000, .8);
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      padding: 0px;
      z-index: 400;

      .message {
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 270px;
        border: 0px;
        border-radius: 10px;

        &.large {
          max-width: 370px;
        }

        .body {
          padding: 20px 15px;
          text-align: center;

          .title {
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            margin-bottom: 10px;
          }

          .text {
            ul {
              text-align: left;
              list-style: inside;
            }
          }

          .content {
            .input {
              margin-top: 10px;

              input {
                border: 1px solid #ccc;
                padding: 5px;
                border-radius: 2px;
                width: 100%;
              }
            }
          }
        }

        .footer {
          display: flex;
          border-top: 1px solid rgba(#000, .1);

          button {
            flex: 1 1 50%;
            padding: 10px 15px;
            border-right: 1px solid rgba(#000, .1);
            font-size: 16px;
            color: #007aff;

            &.red {
              color: #FF3B30;
            }

            &.bold {
              font-weight: 600;
            }
          }
        }
      }

      &.ng-enter {
        opacity: 0;

        .message {
          opacity: 0;
          transform: translate(-50%, -70%);
        }
      }

      &.ng-enter-active {
        opacity: 1;
        transition: .4s;

        .message {
          opacity: 1;
          transform: translate(-50%, -50%);
          will-change: transform;
          transition: .3s;
          transition-delay: .1s;
        }
      }
    }

    &.textarea {
      .message {
        max-width: 350px;

        .body {
          .input {
            text-align: left;

            label {
              color: #797979;
            }
          }
        }
      }

      textarea {
        border: 0px;
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
      }
    }

    &.actionsheet {
      position: fixed;
      background: rgba(#000, .8);
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      padding: 0px;
      z-index: 400;

      .message {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 10px;
        margin-bottom: 0px;

        button {
          display: block;
          width: 100%;
          padding: 15px;
          font-size: 17px;
          color: #007aff;

          &.red {
            color: #FF3B30;
          }

          &.bold {
            font-weight: 600;
          }
        }

        .body {
          background: #fff;
          margin-bottom: 10px;

          .content {
            color: #8F8F8F;
            text-align: center;
            padding: 15px;
            padding-bottom: 20px;

            .title {
              font-weight: 600;
            }

            .text {
              margin-top: 5px;
            }
          }

          .actions {
            button {
              border-top: 1px solid rgba(#000, .1);
            }
          }
        }

        .footer {
          background: #fff;

          button {
            font-weight: 600;
          }
        }

        .body, .footer {
          border-radius: 10px;
          overflow: hidden;
        }
      }

      &.ng-enter {
        opacity: 0;

        .message {
          opacity: 0;
          transform: translate(0%, 100%);
        }
      }

      &.ng-enter-active {
        opacity: 1;
        transition: .4s;

        .message {
          opacity: 1;
          transform: translate(0%, 0%);
          will-change: transform;
          transition: .3s;
          transition-delay: .1s;
        }
      }
    }

    &.prompt_select {
      .message {
        .body {
          .content {
            .input {
              select {
                background: #fff;
                display: block;
                font-family: 'Raleway', sans-serif;
                padding: 10px 15px;
                width: 100%;
                border: 1px solid $light-grey;
                outline: none;
              }
            }
          }
        }
      }
    }
    
    &.prompt_number {
      .message {
        .body {
          .counter {
            display: flex;
            align-items: center;
            border: 2px solid $button-light-grey;
            border-radius: $button-border-radius;
            overflow: hidden;

            & > .button {
              padding: 12px 20px;
              border-radius: 0px;
            }

            & > .value {
              flex: 1 1 auto;
              text-align: center;

              & > .number {
                font-size: 16px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    &.ng-leave {
      opacity: 1;
      transition: .3s;
    }

    &.ng-leave-active {
      opacity: 0;
    }
  }
}

@media (min-width: 600px) {
  alert {
    .alert {
      &.actionsheet {
        .message {
          width: 100%;
          max-width: 650px;
          margin: 0 auto;
        }
      }
    }
  }
}
